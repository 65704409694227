import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import UserContactForm from "./UserContactForm";
import MessentiLogo from "../images/icons/MessentiLogo";
import EventsVisualizationIcon from "../images/icons/EventsVisualizationIcon";
import AutomatedRemindersIcon from "../images/icons/AutomatedRemindersIcon";
import MobileCompatibilityIcon from "../images/icons/MobileCompatibilityIcon";
import moment from "moment";
import FacebookIcon from "@mui/icons-material/Facebook";
import config from "./shared/Config";

const host = process.env.REACT_APP_API_ENDPOINT;

const pages = [
  {
    heading: "Reduce las ausencias",
    paragraph:
      "Messenti ayuda a las empresas a reducir las ausencias enviando recordatorios automáticos a los clientes de sus clientes antes de sus citas.",
  },
  {
    heading: "Retorno de inversión",
    paragraph:
      "Recupera tu inversión desde la primer cita recuperada. El olvido es una de las causas principales de ausencias; evítalo y optimiza tus ingresos.",
  },
  {
    heading: "Mejora la satisfacción del cliente",
    paragraph:
      "Al enviar recordatorios oportunos, Messenti asegura que los clientes estén bien preparados y satisfechos con sus citas.",
  },
  {
    heading: "Ahorra tiempo y esfuerzo",
    paragraph:
      "Con Messenti, las empresas pueden automatizar el proceso de envío de recordatorios, ahorrando tiempo y esfuerzo tanto para la empresa como para los clientes de sus clientes.",
  },
];

const features = [
  {
    title: "Visualización de Eventos",
    description:
      "Visualiza todos tus eventos en un solo lugar, facilitando la gestión de tu agenda.",
    icon: EventsVisualizationIcon,
  },
  {
    title: "Recordatorios Automatizados",
    description:
      "Envía recordatorios automáticos a tus clientes para asegurarte de que no olviden sus citas.",
    icon: AutomatedRemindersIcon,
  },
  {
    title: "Compatibilidad con Dispositivos Móviles y Asistentes",
    description:
      "Integra tu calendario con tu celular, Alexa u otros asistentes para mantener todo sincronizado y al alcance de tu voz.",
    icon: MobileCompatibilityIcon,
  },
];

const FacebookLogin = (props) => {
  const { setContextUser, disabled } = props;
  const fields = "id,name,first_name,last_name,email,picture,location";

  const login = () => {
    window.FB.login(function (response) {
      if (response.authResponse) {
        window.FB.api(`/me`, { fields }, function (response) {
          let email = response.email;
          let displayName = response.name;
          let photo = response.picture.data.url;
          let defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

          handleLogin({
            email,
            displayName,
            photo,
            defaultTimeZone
          });
        });
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    }, { scope: "public_profile,email,user_location" });
  };

  const handleLogin = async (response) => {
    try {
      const user = response;

      let url = `${host}/api/LoadUserProfile`;
      url += "?provider=Facebook";
      url += "&authuser=" + user.email;
      url += "&name=" + user.displayName;
      url += "&defaultTimeZone=" + user.defaultTimeZone;
      url += "&scope=";
      let options = {
        method: "get",
      };
      fetch(url, options)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          let profile = response.profile;
          let subscription = response.subscription;
          let email = profile.authuserLogin;
          let name = profile.displayName;
          let given_name = profile.givenName;
          let family_name = profile.familyName;
          let picture = profile.photo || response.photo;
          let messageTemplateId = profile.messageTemplateId;
          let createdDate = profile.createdDate;
          let currentDate = moment();
          let freeDays = Number(process.env.REACT_APP_FREE_DAYS);
          let daysLeft = freeDays - currentDate.diff(createdDate, "days");
          let timeZone = profile.timeZone;
          let defaultCountryCode = Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1] || 'MX';
          if (!Object.keys(config.settings.countryCodes).includes(profile.countryCode)) {
            defaultCountryCode = 'MX';
          }
          let countryCode = profile.countryCode || defaultCountryCode;
          if (daysLeft < 0) daysLeft = 0;

          let loggedUser = {
            email,
            name,
            given_name,
            family_name,
            picture,
            messageTemplateId,
            daysLeft,
            subscription,
            timeZone,
            countryCode,
          };

          setContextUser(loggedUser);
        });
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={login}
      disabled={disabled}
      startIcon={<FacebookIcon />}
      style={{
        backgroundColor: disabled ? "" : "#1877F2",
        color: "#fff",
        textTransform: "none",
        fontWeight: "bold",
      }}
    >
      Iniciar con Facebook
    </Button>
  );
};

const Header = (props) => {
  let { isLoginDisabled, setContextUser } = props;
  const isMobile = useMediaQuery("(max-width:600px)");

  const dynamicStyles = {
    iconMargin: isMobile ? "6px" : "10px",
    iconSize: isMobile ? 30 : 50,
    textSize: isMobile ? "large" : "xxx-large",
  };

  return (
    <div style={styles.header}>
      <div style={styles.iconContainer}>
        <MessentiLogo
          margin={dynamicStyles.iconMargin}
          width={dynamicStyles.iconSize}
          height={dynamicStyles.iconSize}
        />
        <font style={{ ...styles.brandName, fontSize: dynamicStyles.textSize }}>
          essenti
        </font>
      </div>
      <div style={styles.buttonContainer}>
        <FacebookLogin
          setContextUser={setContextUser}
          disabled={isLoginDisabled}
        />
      </div>
    </div>
  );
};

const Pricing = (props) => {
  return (
    <section
      style={{
        backgroundColor: "#007BFF",
        color: "#fff",
        padding: "40px 40px",
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        borderTop: "1px solid #0056b3",
        borderBottom: "1px solid #0056b3",
        marginBottom: "50px",
      }}
    >
      <h2 style={{ fontSize: "32px", margin: "0 0 20px" }}>
        ¡Prueba nuestro servicio!
      </h2>
      <p style={{ fontSize: "24px" }}>
        Pago mensual: <strong>$199 MXN</strong>
      </p>
      <p style={{ fontSize: "24px" }}>
        Puedes probarlo <strong>gratis durante 30 días</strong>
      </p>
      <p
        style={{
          padding: "10px 20px",
          fontSize: "20px",
          marginTop: "20px",
          backgroundColor: "white",
          color: "#007BFF",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          ":hover": {
            backgroundColor: "#0056b3",
            color: "white",
          },
        }}
      >
        ¡Empieza ahora!*<br></br>
        <br></br>
        <FacebookLogin setContextUser={props.setContextUser} />
      </p>
    </section>
  );
};

const Footer = () => {
  return (
    <div style={styles.footer}>
      <p style={{ marginBottom: "10px" }}>
        Messenti © 2023. Todos los derechos reservados.
      </p>
      <p>
        <Link to="/PrivacyPolicy" style={styles.link}>
          Política de Privacidad
        </Link>
        &nbsp;&nbsp;/&nbsp;&nbsp;
        <Link to="/DataDeletionRequest" style={styles.link}>
          Solicitud eliminación de cuenta
        </Link>
        &nbsp;&nbsp;/&nbsp;&nbsp;
        <Link to="/MetaBusinessIdentity" style={styles.link}>
          Identificador Comercial Meta
        </Link>
        &nbsp;&nbsp;/&nbsp;&nbsp;
        <Link to="/ServiceConditions" style={styles.link}>
          Condiciones de Servicio
        </Link>
      </p>
      <p style={{ margin: "0", alignSelf: "flex-start" }}>
        <small>
          <small>
            * El uso y transferencia de información recibida de las APIs de
            Google por parte de Messenti se adhiere a la
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#1a73e8",
                textDecoration: "none",
                marginLeft: "5px",
              }}
            >
              Política de Datos de Usuario de los Servicios de API de Google
            </a>
            , incluyendo los requisitos de Uso Limitado.
          </small>
        </small>
      </p>
      <p style={{ margin: "0", alignSelf: "flex-start" }}>
        <small>
          <small>
            * <b>Messenti</b> es una aplicación que propociona servicios de
            envío de mensajes electrónicos a nuestros usuarios vía{" "}
            <b>Whatsapp</b> para recordar a sus clientes sobre sus próximas
            visitas programadas. <br></br>
            Para ello, la aplicación accede a servicios proporcionados por{" "}
            <b>Meta</b>, registrada bajo la siguiente identidad:
            <ul>
              <li>Número de identificación: 345788933555967</li>
              <li>
                Nombre legal del negocio: <b>Juan Manuel Ramos Vargas</b>
              </li>
              <li>Identificador fiscal (RFC): RAVJ8009096N2</li>
              <li>
                Correo electrónico:{" "}
                <a href="mailto:info@messenti.com">info@messenti.com</a>
              </li>
              <li>Teléfono móvil: (+52) 8185250571</li>
              <li>
                Dirección: Yucatán 576 Col. República Ote, Saltillo, Coah,
                México
              </li>
              <li>Código Postal: 25280</li>
            </ul>
          </small>
        </small>
      </p>
    </div>
  );
};

const Home = (props) => {
  const { setContextUser } = props;
  const [index, setIndex] = useState(0);
  const [showContent, setShowContent] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isLoginDisabled, setIsLoginDisabled] = useState(true);

  const dynamicStyles = {
    mainContainer: {
      marginLeft: "0px",
      fontFamily: "'Roboto', sans-serif",
      overflowX: "hidden",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    page: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      height: isMobile ? "30vh" : "60vh",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "stretch" : "center",
    },
    headingContainer: {
      textAlign: "left",
      width: isMobile ? "100%" : "50%",
    },
    heading: {
      fontSize: isMobile ? "30px" : "56px",
      marginLeft: isMobile ? "10px" : "30px",
      marginRight: isMobile ? "10px" : "0px",
      marginTop: isMobile ? "30px" : "0px",
    },
    paragraphContainer: {
      textAlign: "right",
      width: isMobile ? "100%" : "40%",
    },
    paragraph: {
      fontSize: "18px",
      textAlign: isMobile ? "left" : "right",
      marginLeft: isMobile ? "10px" : "0px",
      marginRight: isMobile ? "10px" : "30px",
      marginTop: isMobile ? "0px" : "0px",
    },
  };

  const headingProps = useSpring({
    opacity: showContent ? 1 : 0,
    config: { duration: 1000 },
  });

  const paragraphProps = useSpring({
    opacity: showContent ? 1 : 0,
    transform: showContent ? "translateY(0)" : "translateY(20px)",
    config: { duration: 1000 },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowContent(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % 3);
        setShowContent(true);
      }, 1000);
    }, 5000);

    if (!document.getElementById("facebook-jssdk")) {
      (function (d, script) {
        script = d.createElement("script");
        script.id = "facebook-jssdk";
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://connect.facebook.net/es_LA/all.js#version=v20.0&xfbml=true&autoLogAppEvents=true&appId=1902925610187308&status=true&cookie=true";
        d.getElementsByTagName("head")[0].appendChild(script);
      })(document);

      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "1902925610187308",
          cookie: true,
          xfbml: true,
          version: "v20.0",
        });

        setIsLoginDisabled(false);
      };
    } else {
      setIsLoginDisabled(false);
    }

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{ ...dynamicStyles.mainContainer }}>
      <Header
        isLoginDisabled={isLoginDisabled}
        setContextUser={setContextUser}
      />

      <div style={dynamicStyles.page}>
        <animated.div
          style={{ ...headingProps, ...dynamicStyles.headingContainer }}
        >
          <div style={dynamicStyles.heading}>{pages[index].heading}</div>
        </animated.div>
        <animated.div
          style={{ ...paragraphProps, ...dynamicStyles.paragraphContainer }}
        >
          <div style={dynamicStyles.paragraph}>{pages[index].paragraph}</div>
        </animated.div>
      </div>

      <section style={styles.featuresSection}>
        {features.map((feature, index) => (
          <div key={index} style={styles.featureCard}>
            {feature.icon && <feature.icon style={styles.featureIcon} />}
            {!feature.icon && (
              <img
                src={feature.image}
                alt={feature.title}
                style={styles.featureImage}
              />
            )}
            <h3 style={styles.featureTitle}>{feature.title}</h3>
            <p style={styles.featureDescription}>{feature.description}</p>
          </div>
        ))}
      </section>

      <Pricing setContextUser={setContextUser} />
      <br></br>
      <UserContactForm />
      <br></br>
      <br></br>
      <Footer />
    </div>
  );
};

const styles = {
  featuresSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "20px",
    width: "100%",
    boxSizing: "border-box",
  },
  featureCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    margin: "10px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    width: "250px",
    height: "auto",
    boxSizing: "border-box",
    textAlign: "center",
  },
  featureTitle: {
    marginTop: "15px",
    marginBottom: "5px",
    fontSize: "20px",
    fontWeight: "bold",
    height: "3em",
  },
  featureDescription: {
    fontSize: "16px",
    height: "7em",
  },
  featureImage: {
    width: "100px",
    height: "100px",
    marginBottom: "10px",
  },
  featureIcon: {
    display: "block",
    margin: "0 auto",
    width: "100px",
    height: "100px",
    marginBottom: "10px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px 5%",
    boxSizing: "border-box",
    height: "100px",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: "#fff",
    boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: "32px",
    marginRight: "10px",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderTop: "1px solid #ddd",
    backgroundColor: "#f7f7f7",
    width: "100%",
    boxSizing: "border-box",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: "blue",
    cursor: "pointer",
  },
  brandName: {
    fontSize: "xxx-large",
    letterSpacing: "5px",
  },
};

export default Home;
