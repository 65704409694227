import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import "./StripeCheckoutForm.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PoweredByStripeLogo from "../images/icons/PoweredByStripeLogo";
import config from "./shared/Config";

const host = process.env.REACT_APP_API_ENDPOINT;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBKEY);

const StripeElements = ({
  userContext,
  setUserContext,
  setIsSubscribed,
  notificationRef,
  handleClose,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${host}`,
      },
      redirect: "if_required",
    });

    if (error) {
      console.log("[Error]", error);
      notificationRef.current.showNotification(
        "error",
        `Error en el pago: ${error.message}`
      );
      handleClose();
    } else {
      try {
        return await axios
          .get(`${host}/api/usersubscription/${userContext.email}`)
          .then((response) => {
            return response.data;
          })
          .then((subscription) => {
            setUserContext((prevContext) => ({ ...prevContext, subscription }));
            setIsSubscribed(true);
            notificationRef.current.showNotification(
              "success",
              "¡Tu suscripción ha sido realizada con éxito!"
            );
            handleClose();
          })
          .catch((error) => {
            notificationRef.current.showNotification(
              "error",
              "Error al procesar el pago:",
              error.message || error
            );
            console.log("Error: ", error.message);
          });
      } catch (error) {
        notificationRef.current.showNotification(
          "error",
          "Error al procesar el pago:",
          error
        );
        console.log("Error al procesar el pago:", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="payment-element-container">
          <PaymentElement className="StripeElement" options={cardStyle} />
        </div>
      </div>
      <div className="form-row">
        <button type="submit" disabled={!stripe} className="submit-button">
          Suscribirse
        </button>
      </div>
    </form>
  );
};

const StripeCheckoutForm = ({
  userContext,
  setUserContext,
  handleClose,
  setIsSubscribed,
  notificationRef,
}) => {
  const [clientSecret, setClientSecret] = useState("");

    

  let productDetails = {
    name: "Suscribirse a Messenti",
    price: `$${config.settings.countryCodes[userContext.countryCode].cost}`,
    currency: `${config.settings.countryCodes[userContext.countryCode].currency} por mes`,
    description: "Su solución integral de gestión de citas y comunicaciones.",
  };

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios.post(
          `${host}/api/striperegisterpaymentintent/${userContext.email}`
        );
        const clientSecret = response.data.clientSecret;
        return clientSecret;
      } catch (error) {
        console.error("Error al obtener el clientSecret:", error);
      }
    };

    const getClientSecret = async () => {
      const clientSecret = await fetchClientSecret();
      setClientSecret(clientSecret);
    };

    if (!clientSecret) {
      getClientSecret();
    }
  }, [clientSecret, userContext]);

  return (
    <div>
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: clientSecret }}
        >
          <div className="payment-container">
            <div className="product-details">
              <h3 className="price-title">{productDetails.name}</h3>
              <p className="price">
                {productDetails.price} {productDetails.currency}
              </p>
              <p className="price-description">{productDetails.description}</p>
            </div>

            <StripeElements
              userContext={userContext}
              setUserContext={setUserContext}
              setIsSubscribed={setIsSubscribed}
              notificationRef={notificationRef}
              handleClose={handleClose}
              clientSecret={clientSecret}
            />

            <div className="powered-by-stripe">
              <PoweredByStripeLogo />
            </div>
          </div>
        </Elements>
      )}
    </div>
  );
};

const cardStyle = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

export default StripeCheckoutForm;
