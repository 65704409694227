const config = {
  settings: {
    countryCodes: {
      AR: {
        code: "AR",
        prefix: "+549",
        flag: "AR",
        mask: "(999) 999-9999",
        validation: /^\(\d{3}\) \d{3}-\d{4}$/,
        validationMessage: "Debe ser un número de celular válido (XX) XXX-XXXX",
        cost: "9.99",
        currency: "USD",
      },
      CO: {
        code: "CO",
        prefix: "+57",
        flag: "CO",
        mask: "(999) 999-9999",
        validation: /^\(\d{3}\) \d{3}-\d{4}$/,
        validationMessage: "Debe ser un número de celular válido (XXX) XXX-XXXX",
        cost: "9.99",
        currency: "USD",
      },
      ES: {
        code: "ES",
        prefix: "+34",
        flag: "ES",
        mask: "(99) 999-9999",
        validation: /^\(\d{2}\) \d{3}-\d{4}$/,
        validationMessage: "Debe ser un número de celular válido (XX) XXX-XXXX",
        cost: "9.99",
        currency: "EUR",
      },
      MX: {
        code: "MX",
        prefix: "+52",
        flag: "MX",
        mask: "(99) 999-99999",
        validation: /^\(\d{2}\) \d{3}-\d{5}$/,
        validationMessage: "Debe ser un número de celular válido (XX) XXX-XXXXX",
        cost: "199",
        currency: "MXN",
      },
    },
  },
};

export default config;
